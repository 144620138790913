<template>
  <div class="container">
    <div id="player-content" class="content"></div>
    <div class="footnote">
      <p>Norte token images are 2,560 by 2,560 pixels.</p>
    </div>
  </div>
</template>

<script>
  import { computed, ref, watch, onMounted, nextTick } from 'vue'
  import { useStore } from 'vuex'
  import { defineSketch } from '@/services/PlayerService'

  export default {
    name: 'Player',

    setup() {
      // hooks
      const store = useStore()
      const {
        dispatch,
        state: { user, toolPanel },
      } = store

      // Get state
      const walletAddress = computed(() => user.walletAddress)
      const mode = computed(() => toolPanel.mode)
      const style = computed(() => toolPanel.style)
      const var1 = computed(() => toolPanel.var1)
      const var2 = computed(() => toolPanel.var2)
      const background = computed(() => toolPanel.background)
      const showAddress = computed(() => toolPanel.showAddress)
      const iteration = computed(() => toolPanel.iteration)

      const modeRef = ref(mode)
      const styleRef = ref(style)
      const var1Ref = ref(var1)
      const var2Ref = ref(var2)
      const backgroundRef = ref(background)
      const showAddressRef = ref(showAddress)
      const walletAddressRef = ref(walletAddress)
      const iterationRef = ref(iteration)

      // Methods
      const getParams = () => ({
        address: walletAddressRef.value,
        mode: modeRef.value,
        style: styleRef.value,
        var1: var1Ref.value,
        var2: var2Ref.value,
        background: backgroundRef.value,
        showAddress: showAddressRef.value,
        iteration: iterationRef.value,
      })

      const launchCanvas = (params) => {
        if (walletAddressRef.value) {
          const p5 = window.p5

          const sketch = defineSketch(params)

          new p5(sketch, 'player-content')
        }
      }

      const refreshCanvas = async () => {
        document.getElementById('player-content').innerHTML = ''
        await nextTick()
        launchCanvas(getParams())
        // console.log('setting to false', dispatch)
        setTimeout(() => {
          dispatch('setRenderingCanvas', false)
        }, 500)
      }

      onMounted(async () => {
        await refreshCanvas()
      })

      watch(
        [
          modeRef,
          styleRef,
          var1Ref,
          var2Ref,
          backgroundRef,
          showAddressRef,
          walletAddressRef,
        ],
        async () => {
          await refreshCanvas()
        }
      )

      return {}
    },
  }
</script>

<style>
  .p5Canvas {
    width: 100% !important;
    height: 100% !important;
    min-width: 150px !important;
    min-height: 150px !important;
    max-width: 900px !important;
    max-height: 900px !important;
    display: flex;
  }
</style>

<style scoped>
  .container {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .content {
    max-width: 100%;
    max-height: 100%;
    border: 1px solid #e6e6e6;
  }

  .footnote {
    font-size: 0.75em;
    text-align: center;
    width: 100%;
    max-width: 900px;
    justify-self: start;
  }
</style>
