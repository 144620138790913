<template>
  <div>
    <el-button type="success" @click="doUpdate()">
      Update
      <el-icon class="el-icon--right"><Edit /></el-icon>
    </el-button>
    <p class="update-message">
      Update your token to refresh its artwork. Please note Mode, Style and
      creator's address cannot be changed.
    </p>
  </div>
</template>

<script>
  import { computed, defineComponent } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { ElNotification } from 'element-plus'
  import { Edit } from '@element-plus/icons'

  import NorteContractService from '@/services/NorteContractService'

  export default defineComponent({
    name: 'UpdateButton',
    components: { Edit },
    setup() {
      const store = useStore()
      const {
        dispatch,
        state: { contractState, toolPanel, user, root },
      } = store

      const web3 = computed(() => contractState.web3)
      const contract = computed(() => contractState.contract)
      const walletAddress = computed(() => user.walletAddress)
      const selectedProject = computed(() => root.selectedProject)

      const var1 = computed(() => toolPanel.var1)
      const var2 = computed(() => toolPanel.var2)
      const background = computed(() => toolPanel.background)
      const showAddress = computed(() => toolPanel.showAddress)

      const notificationTime = process.env.VUE_APP_NOTIFICATION_DURATION

      const route = useRoute()
      const router = useRouter()
      const tokenID = route.params.token_id
      const projectSlug = route.params.project_slug

      const resetTokens = () => {
        dispatch('resetTokensData')
        dispatch('resetMyTokens')
      }

      const doUpdate = async () => {
        dispatch('setLoading', true)

        let result = null

        const params = {
          tokenID: tokenID,
          var1: var1.value,
          var2: var2.value,
          background: background.value,
          showAddress: showAddress.value,
          contractAddress: selectedProject.value?.configuration.contractAddress,
          walletAddress: walletAddress.value,
        }

        try {
          const norteContractService = new NorteContractService(
            web3.value,
            contract.value
          )

          result = await norteContractService.updateMint(params)

          console.log('result: ', result)
          ElNotification({
            title: 'Success',
            message: 'Update successful.',
            type: 'success',
            duration: notificationTime,
          })

          resetTokens()

          router.push({
            path: `/tokens/${projectSlug}/${tokenID}`,
            query: {
              hasUpdated: true,
            },
          })
        } catch (e) {
          if (e.code === 4001) {
            ElNotification({
              title: 'Error',
              message: 'Update cancelled.',
              type: 'error',
              duration: notificationTime,
            })
          } else if (e.code === -32603) {
            ElNotification({
              title: 'Error',
              message: 'Error processing TX.',
              type: 'error',
              duration: notificationTime,
            })
          } else {
            ElNotification({
              title: 'Error',
              message: `Update failed: ${e.message}`,
              type: 'error',
              duration: notificationTime,
            })
          }
        }

        dispatch('setLoading', false)
      }

      return {
        doUpdate,
        Edit,
      }
    },
  })
</script>

<style scoped>
  .update-message {
    left: 0px;
    font-size: 0.75em;
    margin-top: 6px;
  }
</style>
