<template>
  <div class="container" v-loading="loading">
    <el-row v-if="devMode">
      <el-col>
        <div>
          <el-button type="primary" @click="generateRandomAddress()">
            Random Address
          </el-button>
        </div>
      </el-col>
    </el-row>
    <el-row class="mobile-mint-btn-container" v-if="!editModeRef">
      <el-col>
        <MintButton />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div v-if="editModeRef">
          <UpdateButton />
        </div>
        <div class="s1-project-btns" v-else>
          <div class="desktop-mint-btn-container">
            <MintButton />
          </div>
          <el-button type="primary" @click="randomizeSelection">
            Randomize
            <el-icon class="el-icon--right"><MagicStick /></el-icon>
          </el-button>
          <el-button type="primary" @click="browseGallery()">
            Browse Gallery
            <el-icon class="el-icon--right"><Grid /></el-icon>
          </el-button>
        </div>
      </el-col>
    </el-row>
    <el-row class="select-mode">
      <el-col style="margin-top: 5px">
        <span>Mode</span>
        <el-radio-group v-model="mode" size="small">
          <el-radio
            border
            label="1"
            @click="dispatchSetMode('1')"
            :disabled="editModeRef"
          >
            {{ MODES['1'].name }}
          </el-radio>
          <el-radio
            border
            label="2"
            @click="dispatchSetMode('2')"
            :disabled="editModeRef"
          >
            {{ MODES['2'].name }}
          </el-radio>
          <el-radio
            border
            label="3"
            @click="dispatchSetMode('3')"
            :disabled="editModeRef"
          >
            {{ MODES['3'].name }}
          </el-radio>
          <el-tooltip
            class="item"
            effect="dark"
            content="Congratulations, as a CryptoArte holder you have access to this exclusive experience."
            placement="top-start"
            :disabled="!whitelistedAnyRef || editModeRef"
            :show-after="1000"
          >
            <el-badge
              value="Pro"
              class="item"
              type="warning"
              is-dot
              :hidden="!whitelistedAnyRef || editModeRef"
            >
              <el-radio
                border
                label="4"
                :disabled="!whitelistedAnyRef || editModeRef"
                @click="dispatchSetMode('4', !whitelistedAnyRef)"
              >
                {{ MODES['4'].name }}
              </el-radio>
            </el-badge>
          </el-tooltip>
        </el-radio-group>
      </el-col>
    </el-row>
    <el-row class="select-style">
      <el-col>
        <span>Style</span>
        <el-radio-group v-model="style" size="small">
          <el-radio
            label="1"
            border
            @click="dispatchSetStyle('1')"
            :disabled="editModeRef"
          >
            {{ MODES[mode].styles['1'].name }}
          </el-radio>
          <el-radio
            label="2"
            border
            @click="dispatchSetStyle('2')"
            :disabled="editModeRef"
          >
            {{ MODES[mode].styles['2'].name }}
          </el-radio>
          <el-radio
            label="3"
            border
            @click="dispatchSetStyle('3')"
            :disabled="editModeRef"
          >
            {{ MODES[mode].styles['3'].name }}
          </el-radio>
          <el-tooltip
            class="item"
            effect="dark"
            content="Congratulations, as a CryptoArte holder you have access to this exclusive experience."
            placement="top-start"
            :disabled="!whitelistedAnyRef || mode === '4' || editModeRef"
            :show-after="1000"
          >
            <el-badge
              value="Pro"
              class="item"
              type="warning"
              is-dot
              :hidden="!whitelistedAnyRef || mode === '4' || editModeRef"
            >
              <el-radio
                label="4"
                border
                :disabled="!whitelistedAnyRef || editModeRef"
                @click="dispatchSetStyle('4', !whitelistedAnyRef)"
              >
                {{ MODES[mode].styles['4'].name }}
              </el-radio>
            </el-badge>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Congratulations, as a CryptoArte Prime Holder or Top (>= 10) Holder you have access to this exclusive experience."
            placement="top-start"
            :disabled="!whitelistedPrimeRef || editModeRef"
            :show-after="1000"
          >
            <el-badge
              value="Pro"
              class="item"
              type="warning"
              is-dot
              :hidden="!whitelistedPrimeRef || editModeRef"
            >
              <el-radio
                label="6"
                border
                :disabled="!whitelistedPrimeRef || editModeRef"
                @click="dispatchSetStyle('6', !whitelistedPrimeRef)"
              >
                {{ MODES[mode].styles['6'].name }}
              </el-radio>
            </el-badge>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Congratulations, as a CryptoArte Early Collector or Top (>=20) Holder you have access to this exclusive experience."
            placement="top-start"
            :disabled="!whitelistedEarlyRef || editModeRef"
            :show-after="1000"
          >
            <el-badge
              value="Pro"
              class="item"
              type="warning"
              is-dot
              :hidden="!whitelistedEarlyRef || editModeRef"
            >
              <el-radio
                label="5"
                border
                :disabled="!whitelistedEarlyRef || editModeRef"
                @click="dispatchSetStyle('5', !whitelistedEarlyRef)"
              >
                {{ MODES[mode].styles['5'].name }}
              </el-radio>
            </el-badge>
          </el-tooltip>
        </el-radio-group>
      </el-col>
    </el-row>
    <el-row>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="slider-container">
          <span>
            {{ getVarName('1') }}: {{ var1Ref }}
            <div class="slider-btns">
              <el-button
                type="primary"
                size="small"
                @click="setStepVar1(false)"
                :disabled="var1Ref < 1"
              >
                <el-icon><Minus /></el-icon>
              </el-button>
              <el-button
                type="primary"
                size="small"
                @click="setStepVar1(true)"
                :disabled="var1Ref > 254"
              >
                <el-icon><Plus /></el-icon>
              </el-button>
            </div>
          </span>
          <el-slider
            v-model="var1Ref"
            :min="0"
            :max="255"
            @input="dispatchSetVar1"
          ></el-slider>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="slider-container">
          <span>
            {{ getVarName('2') }}: {{ var2Ref }}
            <div class="slider-btns">
              <el-button
                type="primary"
                size="small"
                @click="setStepVar2(false)"
                :disabled="var2Ref < 1"
              >
                <el-icon><Minus /></el-icon>
              </el-button>
              <el-button
                type="primary"
                size="small"
                @click="setStepVar2(true)"
                :disabled="var2Ref > 254"
              >
                <el-icon><Plus /></el-icon>
              </el-button>
            </div>
          </span>
          <el-slider
            v-model="var2Ref"
            :min="0"
            :max="255"
            @input="dispatchSetVar2"
          ></el-slider>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <span>Background</span>
        <el-radio-group v-model="background" size="small">
          <el-radio-button :label="false" @click="dispatchSetBackground(false)">
            White
          </el-radio-button>
          <el-radio-button :label="true" @click="dispatchSetBackground(true)">
            Black
          </el-radio-button>
        </el-radio-group>
      </el-col>
      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <span>Display address</span>
        <el-switch
          v-model="showAddress"
          @change="dispatchSetShowAddress(!showAddress)"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { computed, ref, watch, onBeforeMount, onUpdated } from 'vue'
  import { useStore } from 'vuex'
  import crypto from 'crypto'

  import MintButton from '../mint-button'
  import UpdateButton from '../update-button'

  import { DEFAULT_VARS, MODES } from '@/constants/toolPanelConstants'
  import { Grid, Plus, Minus, MagicStick } from '@element-plus/icons'
  import { ElNotification } from 'element-plus'
  import { useRouter } from 'vue-router'

  export default {
    name: 'ToolPanel',

    components: {
      MintButton,
      UpdateButton,
      Grid,
      Plus,
      Minus,
      MagicStick,
    },

    setup() {
      // hooks
      const store = useStore()
      const {
        dispatch,
        state: { user, toolPanel },
      } = store

      const router = useRouter()
      // Read from state
      const mode = computed(() => toolPanel.mode)
      const style = computed(() => toolPanel.style)
      const var1 = computed(() => toolPanel.var1)
      const var2 = computed(() => toolPanel.var2)
      const background = computed(() => toolPanel.background)
      const showAddress = computed(() => toolPanel.showAddress)
      const loading = computed(() => toolPanel.loading)
      const editMode = computed(() => toolPanel.editMode)

      const walletAddress = computed(() => user.walletAddress)
      const whitelistedEarly = computed(() => user.whitelistedEarly)
      const whitelistedHolder = computed(() => user.whitelistedHolder)
      const whitelistedPrime = computed(() => user.whitelistedPrime)

      const var1Ref = ref(Number.parseInt(var1.value))
      const var2Ref = ref(Number.parseInt(var2.value))

      const walletAddressRef = ref(walletAddress)
      const whitelistedEarlyRef = ref(whitelistedEarly)
      const whitelistedPrimeRef = ref(whitelistedPrime)

      const editModeRef = ref(editMode)

      const notifyWhitelistedSettings = computed(
        () => user.notifyWhitelistedSettings
      )
      const notifyWhitelistedSettingsRef = ref(notifyWhitelistedSettings)

      const whitelistedAnyRef = ref(
        whitelistedEarly.value ||
          whitelistedHolder.value ||
          whitelistedPrime.value
      )

      const devMode = ref(process.env.VUE_APP_ART_DEV_MODE === 'true')
      const notificationTime = process.env.VUE_APP_NOTIFICATION_DURATION

      onBeforeMount(() => {
        if (notifyWhitelistedSettingsRef.value) {
          ElNotification({
            title: 'Dear user',
            message:
              "We're sorry, you are not whitelisted for this mode or style however we've landed you on similar settings.",
            type: 'warning',
            duration: notificationTime,
          })
          dispatch('setNotifyWhitelistedSettings', false)
        }
      })

      onUpdated(() => {
        updateRefs()
      })

      // Methods
      const dispatchSetMode = async (value, disabled = false) => {
        if (!disabled) {
          dispatch('setRenderingCanvas', true)
          dispatch('setMode', value)
        }
      }

      const dispatchSetStyle = async (value, disabled = false) => {
        if (!disabled) {
          dispatch('setRenderingCanvas', true)
          dispatch('setStyle', value)
        }
      }

      const dispatchSetBackground = async (value) => {
        dispatch('setRenderingCanvas', true)
        dispatch('setBackground', value)
      }

      const dispatchSetShowAddress = async (value) => {
        dispatch('setShowAddress', value)
      }

      const dispatchSetVar1 = (value) => {
        if (value != var1.value) {
          dispatch('setRenderingCanvas', true)
          dispatch('setVar1', value)
        }
      }

      const dispatchSetVar2 = (value) => {
        if (value != var2.value) {
          dispatch('setRenderingCanvas', true)
          dispatch('setVar2', value)
        }
      }

      const setStepVar1 = (isIncrement) => {
        dispatch('setRenderingCanvas', true)
        if (isIncrement) {
          dispatch('setVar1', (var1Ref.value += 1))
        } else {
          dispatch('setVar1', (var1Ref.value -= 1))
        }
      }

      const setStepVar2 = (isIncrement) => {
        dispatch('setRenderingCanvas', true)
        if (isIncrement) {
          dispatch('setVar2', (var2Ref.value += 1))
        } else {
          dispatch('setVar2', (var2Ref.value -= 1))
        }
      }

      const dispatchSetWalletAddress = (value) => {
        dispatch('setWalletAddress', value)
      }

      const randomizeSelection = () => {
        dispatch('setRenderingCanvas', true)

        let availableModes = ['1', '2', '3']
        let availableStyles = ['1', '2', '3']

        if (whitelistedAnyRef.value) {
          availableModes.push('4')
          availableStyles.push('4')
        } else {
          availableModes.filter((e) => e !== '4')
          availableStyles.filter((e) => e !== '4')
        }

        if (whitelistedEarlyRef.value) {
          availableStyles.push('5')
        } else {
          availableStyles.filter((e) => e !== '5')
        }

        if (whitelistedPrimeRef.value) {
          availableStyles.push('6')
        } else {
          availableStyles.filter((e) => e !== '6')
        }

        let randomMode = Math.floor(Math.random() * availableModes.length)
        dispatch('setMode', availableModes[randomMode])

        let randomStyle = Math.floor(Math.random() * availableStyles.length)
        dispatch('setStyle', availableStyles[randomStyle])

        let randomVar1 = Math.floor(Math.random() * 255)
        var1Ref.value = randomVar1
        dispatch('setVar1', randomVar1)

        let randomVar2 = Math.floor(Math.random() * 255)
        var2Ref.value = randomVar2
        dispatch('setVar2', randomVar2)

        let randomBlackBackground = Math.random() < 0.5
        dispatch('setBackground', randomBlackBackground)

        let randomShowAddress = Math.random() < 0.5
        dispatch('setShowAddress', randomShowAddress)
      }

      const getVarName = (key) => {
        if (MODES[mode.value].styles[style.value].vars) {
          return MODES[mode.value].styles[style.value].vars[key]
        }

        if (MODES[mode.value].vars) {
          return MODES[mode.value].vars[key]
        }

        return DEFAULT_VARS[key]
      }

      const generateRandomAddress = () => {
        const randomAddress = `0x${crypto.randomBytes(20).toString('hex')}`
        dispatchSetWalletAddress(randomAddress)
      }

      const updateRefs = () => {
        var1Ref.value = Number.parseInt(var1.value)
        var2Ref.value = Number.parseInt(var2.value)

        whitelistedAnyRef.value =
          whitelistedEarly.value ||
          whitelistedHolder.value ||
          whitelistedPrime.value
      }

      const browseGallery = () => {
        dispatch('resetTokenAttrs')
        dispatch('resetTokensData')
        router.push({ path: '/tokens/norte', query: {} })
      }

      watch([walletAddressRef], () => {
        updateRefs()

        if (!whitelistedAnyRef.value && mode.value === '4') {
          dispatch('setMode', '1')
          dispatch('setStyle', '1')
        }

        if (
          (!whitelistedAnyRef.value && style.value === '4') ||
          (!whitelistedPrimeRef.value && style.value === '6') ||
          (!whitelistedEarlyRef.value && style.value === '5')
        ) {
          dispatch('setStyle', '1')
        }
      })

      return {
        loading,
        background,
        devMode,
        mode,
        MODES,
        showAddress,
        style,
        var1Ref,
        var2Ref,
        DEFAULT_VARS,
        whitelistedAnyRef,
        whitelistedEarlyRef,
        whitelistedPrimeRef,
        dispatchSetMode,
        dispatchSetStyle,
        dispatchSetBackground,
        dispatchSetShowAddress,
        dispatchSetVar1,
        dispatchSetVar2,
        getVarName,
        generateRandomAddress,
        Grid,
        Plus,
        Minus,
        MagicStick,
        setStepVar1,
        setStepVar2,
        randomizeSelection,
        browseGallery,
        editModeRef,
      }
    },
  } // end export default
</script>

<style>
  .select-mode .el-badge__content.is-fixed.is-dot,
  .select-style .el-badge__content.is-fixed.is-dot {
    right: 15px;
  }
</style>

<style scoped>
  .container {
    text-align: left;
    padding: 0 20px;
  }

  .title-container {
    margin-top: 0 !important;
  }

  .title-container h3 {
    text-align: center;
    margin: 0;
  }

  .container .el-row {
    margin: 20px 0;
  }

  .container .el-col span {
    display: flex;
    margin-bottom: 5px;
  }

  .select-mode .el-radio,
  .select-style .el-radio {
    margin-left: 0 !important;
    width: 110px;
    text-align: center;
    margin: 0 10px 10px 0;
  }

  .slider-container .el-slider {
    margin: 0 5px;
  }

  .slider-container .slider-btns {
    margin: 0 20px;
  }

  .s1-project-btns {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .desktop-mint-btn-container {
    display: flex;
  }

  .mobile-mint-btn-container {
    display: none;
  }

  label.is-disabled {
    pointer-events: none;
  }

  /* Specific styles for Tablet Devices */
  @media screen and (max-width: 900px) {
    .container {
      padding: 0 10px;
    }
  }

  /* Specific styles for Mobile Devices */
  @media screen and (max-width: 575px) {
    .container {
      padding: 0 6px;
    }

    .s1-project-btns {
      margin-bottom: 0;
    }

    .desktop-mint-btn-container {
      display: none;
    }

    .mobile-mint-btn-container {
      display: flex;
      margin-bottom: 40px !important;
    }
  }
</style>
