<template>
  <div>
    <el-button
      type="success"
      v-if="allowMinting"
      :disabled="
        isPaused ||
        (isPublicMintingPaused && walletType == 'public') ||
        hasMintedCombination ||
        renderingCanvas
      "
      @click="doMinting()"
    >
      Mint {{ formatPrice() }}
    </el-button>
    <span class="mint-message">
      {{ allowMintingMessage }}
    </span>
  </div>
</template>

<script>
  import { computed, defineComponent, ref, watch, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { ElNotification } from 'element-plus'

  import NorteContractService from '@/services/NorteContractService'
  import { getSignature } from '@/services/WalletService'

  export default defineComponent({
    name: 'MintButton',
    components: {},
    setup() {
      const store = useStore()
      const {
        dispatch,
        state: { contractState, toolPanel, user, root },
      } = store

      const renderingCanvas = computed(() => toolPanel.renderingCanvas)

      const web3 = computed(() => contractState.web3)
      const contract = computed(() => contractState.contract)
      const walletAddress = computed(() => user.walletAddress)
      const selectedProject = computed(() => root.selectedProject)
      const whitelistedEarly = computed(() => user.whitelistedEarly)
      const whitelistedHolder = computed(() => user.whitelistedHolder)
      const whitelistedPrime = computed(() => user.whitelistedPrime)

      const mode = computed(() => toolPanel.mode)
      const style = computed(() => toolPanel.style)
      const var1 = computed(() => toolPanel.var1)
      const var2 = computed(() => toolPanel.var2)
      const background = computed(() => toolPanel.background)
      const showAddress = computed(() => toolPanel.showAddress)

      const hasMinted = ref(false)
      const hasMintedCombination = ref(false)
      const mintedCombinations = ref({})
      const price = ref('')

      const supplyLeft = ref(0)
      const allowMinting = ref(true)
      const allowMintingMessage = ref('')

      const isPaused = ref(false)
      const isPublicMintingPaused = ref(false)
      const walletType = ref('')

      const contractRef = ref(contract)
      const walletAddressRef = ref(walletAddress)
      const notificationTime = process.env.VUE_APP_NOTIFICATION_DURATION

      const getType = () => {
        let type = 'public'

        if (whitelistedEarly.value && style.value !== '6') {
          type = 'early'
        } else if (whitelistedPrime.value) {
          type = 'prime'
        } else if (whitelistedHolder.value) {
          type = 'holder'
        }

        return type
      }

      const retrieveSignature = async () => {
        // Get data signature from firebase
        const { signature } = await getSignature(walletAddress.value, getType())

        return signature
      }

      const resetTokens = () => {
        dispatch('resetTokensData')
        dispatch('resetMyTokens')
      }

      const doMinting = async () => {
        dispatch('setLoading', true)

        const norteContractService = new NorteContractService(
          web3.value,
          contract.value
        )

        let result = null

        const params = {
          mode: mode.value,
          style: style.value,
          var1: var1.value,
          var2: var2.value,
          background: background.value,
          showAddress: showAddress.value,
          price: price.value,
          contractAddress: selectedProject.value?.configuration.contractAddress,
          walletAddress: walletAddress.value,
        }

        try {
          if (
            whitelistedEarly.value ||
            whitelistedPrime.value ||
            whitelistedHolder.value
          ) {
            const signature = await retrieveSignature()

            if (!signature) {
              ElNotification({
                title: 'Error',
                message: 'Not whitelisted.',
                type: 'error',
                duration: notificationTime,
              })
              dispatch('setLoading', false)
              return
            }

            params.signature = signature
          }

          if (whitelistedEarly.value && style.value !== '6') {
            result = await norteContractService.cryptoArteEarlyCollectorMint(
              params
            )
          } else if (whitelistedPrime.value) {
            result = await norteContractService.cryptoArtePrimeHolderMint(
              params
            )
          } else if (whitelistedHolder.value) {
            result = await norteContractService.cryptoArteHolderMint(params)
          } else {
            result = await norteContractService.publicMint(params)
          }

          console.log('result: ', result)
          ElNotification({
            title: 'Success',
            message: 'Minting successful.',
            type: 'success',
            duration: notificationTime,
          })

          resetTokens()

          await retrieveContractInformation()
        } catch (e) {
          if (e.code === 4001) {
            ElNotification({
              title: 'Error',
              message: 'Minting cancelled.',
              type: 'error',
              duration: notificationTime,
            })
          } else if (e.code === -32603) {
            ElNotification({
              title: 'Error',
              message: 'Error processing TX.',
              type: 'error',
              duration: notificationTime,
            })
          } else {
            ElNotification({
              title: 'Error',
              message: `Minting failed: ${e.message}`,
              type: 'error',
              duration: notificationTime,
            })
          }
        }

        dispatch('setLoading', false)
      }

      const setPrice = (contractInformation) => {
        if (whitelistedEarly.value === true && style.value !== '6') {
          price.value = contractInformation.earlyCollectorPrice
        } else if (whitelistedPrime.value === true) {
          price.value = contractInformation.primeHolderPrice
        } else if (whitelistedHolder.value === true) {
          price.value = contractInformation.holderPrice
        } else {
          price.value = contractInformation.publicPrice
        }
      }

      const formatPrice = () => {
        if (price.value === '') {
          return ''
        }

        return price.value === 0 ? '(free)' : `(${price.value} eth)`
      }

      const validateAllowMinting = () => {
        const type = getType()
        allowMintingMessage.value = ''

        if (hasMintedCombination.value) {
          allowMintingMessage.value =
            'You have already minted this combination. Check out the other styles to mint additional tokens.'
        } else if (isPaused.value) {
          allowMintingMessage.value = 'Minting has not started yet.'
        } else if (isPublicMintingPaused.value) {
          if (type === 'public') {
            allowMintingMessage.value = 'Public minting has not started yet.'
          }
        } else {
          if (type === 'public' && supplyLeft.value < 1) {
            // allowMinting.value = false
            allowMintingMessage.value = 'Public minting Sold Out.'
          } else {
            allowMintingMessage.value = `You have one mint left for this style.`
            if (type === 'public') {
              allowMintingMessage.value += ` There are ${supplyLeft.value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} public mints available.`
            }
          }
        }
      }

      const refreshMintedCombination = () => {
        hasMintedCombination.value =
          mintedCombinations.value[`${mode.value}${style.value}`]
        validateAllowMinting()
      }

      const retrieveContractInformation = async () => {
        await dispatch('setLoading', true)

        const norteContractService = new NorteContractService(
          web3.value,
          contract.value
        )

        const contractInformation = await norteContractService.readContract(
          walletAddress.value
        )

        setPrice(contractInformation)
        hasMinted.value = contractInformation.hasMinted
        mintedCombinations.value = contractInformation.mintedCombinations
        supplyLeft.value = Number.parseInt(contractInformation.supplyLeft)
        isPaused.value = contractInformation.isPaused
        isPublicMintingPaused.value = contractInformation.isPublicMintingPaused
        walletType.value = getType()

        refreshMintedCombination()
        await dispatch('setLoading', false)
      }

      onMounted(() => {
        if (contractRef.value) {
          retrieveContractInformation()
        }
      })

      watch([contractRef, walletAddressRef], () => {
        if (contractRef.value && walletAddressRef.value) {
          retrieveContractInformation()
        }
      })

      watch([mode, style], () => {
        refreshMintedCombination()
      })

      return {
        allowMinting,
        allowMintingMessage,
        hasMinted,
        hasMintedCombination,
        renderingCanvas,
        isPaused,
        isPublicMintingPaused,
        walletType,
        doMinting,
        formatPrice,
      }
    },
  })
</script>

<style scoped>
  .mint-message {
    position: absolute;
    top: 30px;
    left: 0px;
    font-size: 0.75em;
    margin-top: 6px;
  }
</style>
